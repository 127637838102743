import { QueryRenderer, graphql, useRelayEnvironment } from '@kiwicom/account-relay';
import { useRouter } from 'next/router';
import ReferFriendIntro from '@referFriend/Intro/ReferFriendIntro';
import { useFeature } from '@kiwicom/darwin';
import { ErrorScreen } from '@kiwicom/account-components';

import type { ReferFriendIntroQuery$data as Response } from './__generated__/ReferFriendIntroQuery.graphql';

export const referFriendIntroQuery = graphql`
  query ReferFriendIntroQuery($token: String!, $currencyId: String!) {
    ...ReferFriendIntro_data @arguments(token: $token, currencyId: $currencyId)
  }
`;

type Props = {
  readonly currency: string;
};

const ReferFriendIntroQuery = ({ currency }: Props) => {
  const environment = useRelayEnvironment();
  const { query } = useRouter();
  const isReferFriendEnabled = useFeature('REFER_FRIEND');

  if (isReferFriendEnabled) {
    return (
      <QueryRenderer
        environment={environment}
        query={referFriendIntroQuery}
        variables={{ token: query.token, currencyId: currency }}
        onResponse={(renderProps: Response) => <ReferFriendIntro root={renderProps} />}
      />
    );
  }
  return <ErrorScreen />;
};

export default ReferFriendIntroQuery;
