import { Text, Flex, Heading, ButtonLink, Button } from '@kiwicom/account-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBrand } from '@kiwicom/nitro/lib/services/brand/context';
import { Illustration } from '@kiwicom/orbit-components';
import Link from 'next/link';
import { Media } from '@kiwicom/account-global-contexts';
import { events, useLogOnMount } from '@kiwicom/account-tracking';
import { QUERIES } from '@kiwicom/orbit-components/lib/utils/mediaQuery';
import { useRouter } from 'next/router';

import { FlexWithRightMargin, MediaWrapper } from './styled';

type ReferFriendTokenErrorProps = {
  referFriendTokenValidationErrorType: string;
  inviterAmount?: string;
  inviteeAmount?: string;
};

const ReferFriendTokenErrorScreen = ({
  referFriendTokenValidationErrorType,
  inviterAmount,
  inviteeAmount,
}: ReferFriendTokenErrorProps) => {
  const intl = useIntl();
  const { locale } = intl;
  const { company_name: companyName } = useBrand();
  const { query } = useRouter();
  const href = `/${query.language}/user?tab=refer-friend`;

  useLogOnMount(events.referFriend.SHOWED_ERROR_SCREEN, {
    errorType: referFriendTokenValidationErrorType,
  });

  return (
    <Flex
      height={{ _: 'calc(100vh - 52px)', largeMobile: 'calc(100vh - 64px)' }}
      flexDirection="column"
      justifyContent={{ _: 'space-between', largeMobile: 'center' }}
      alignItems="center"
      maxWidth="1024px"
      margin="0 auto"
      padding="0 32px"
    >
      <Flex
        alignItems="center"
        flexDirection={{ _: 'column-reverse', largeMobile: 'row' }}
        flex="3"
      >
        <Flex flexDirection="column" flex="2">
          {referFriendTokenValidationErrorType === 'ExpiredTokenError' && (
            <>
              <Heading spaceAfter="medium" type="title2">
                <FormattedMessage
                  id="account_v2.refer_friend.expired_link.title"
                  defaultMessage="This invitation link has expired, but don’t worry!"
                />
              </Heading>
              <Text size="large" spaceAfter="medium">
                <FormattedMessage
                  id="account_v2.refer_friend.expired_link.description"
                  defaultMessage="Ask your friend to create a new link and send it to you. After you create your account, you’ll get a {promoCodeValue} promo code for your next trip."
                  values={{ promoCodeValue: inviteeAmount }}
                />
              </Text>
              <Media greaterThanOrEqual={QUERIES.LARGEMOBILE}>
                <Flex alignItems="center" spacing="600">
                  <Link passHref as={`/${locale}`} href="/[language]" legacyBehavior>
                    <ButtonLink compact href={`/${locale}`} dataTest="ExploreButton">
                      <FormattedMessage
                        id="account_v2.component.explore_button"
                        defaultMessage="Explore {companyName}"
                        values={{ companyName }}
                      />
                    </ButtonLink>
                  </Link>
                </Flex>
              </Media>
            </>
          )}
          {referFriendTokenValidationErrorType === 'ReferFriendNotEligibleError' && (
            <>
              <Heading spaceAfter="medium" type="title2">
                <FormattedMessage
                  id="account_v2.refer_friend.not_eligible.title"
                  defaultMessage="Sorry, you can’t get this promo code"
                />
              </Heading>
              <Text size="large" spaceAfter="large">
                <FormattedMessage
                  id="account_v2.refer_friend.not_eligible.description"
                  defaultMessage="Since you already traveled with us, you can’t get this promo code, but you can invite your friends to sign up and book a trip with us and we’ll send you a {promoCodeValue} promo code."
                  values={{ promoCodeValue: inviterAmount }}
                />
              </Text>
              <Media greaterThanOrEqual={QUERIES.TABLET}>
                <FlexWithRightMargin
                  flexDirection={{ _: 'column', tablet: 'row' }}
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing="600"
                >
                  <Link passHref as={href} href="/[language]/user?tab=refer-friend">
                    <Button fullWidthUntil={QUERIES.TABLET}>
                      <FormattedMessage
                        id="account_v2.components.refer_friend"
                        defaultMessage="Refer a friend"
                      />
                    </Button>
                  </Link>
                  <ButtonLink href={`/${locale}`}>
                    <FormattedMessage
                      id="account_v2.component.explore_button"
                      defaultMessage="Explore {companyName}"
                      values={{ companyName }}
                    />
                  </ButtonLink>
                </FlexWithRightMargin>
              </Media>
            </>
          )}

          {referFriendTokenValidationErrorType === 'ReferralTokenOwnerError' && (
            <>
              <Heading spaceAfter="medium" type="title2">
                <FormattedMessage
                  id="account_v2.refer_friend.owner_link.title"
                  defaultMessage="It seems that you’ve opened your own invitation link"
                  description="Designer: Anja Bošnjak; Figma: https://www.figma.com/file/3RibGwfx31SJ0UdWk3Xmku/%5BAccount%5D-Refer-a-friend?node-id=2028%3A42768"
                />
              </Heading>
              <Text size="large" spaceAfter="medium">
                <FormattedMessage
                  id="account_v2.refer_friend.owner_link.description"
                  defaultMessage="Share it with your friends instead so they can create an account and help you earn your promo code."
                  description="Designer: Anja Bošnjak; Figma: https://www.figma.com/file/3RibGwfx31SJ0UdWk3Xmku/%5BAccount%5D-Refer-a-friend?node-id=2028%3A42768"
                />
              </Text>
              <Media greaterThanOrEqual={QUERIES.LARGEMOBILE}>
                <Flex flexDirection={{ _: 'column', tablet: 'row' }} alignItems="center">
                  <Link passHref as={href} href="/[language]/user?tab=refer-friend">
                    <Button>
                      <FormattedMessage
                        id="account_v2.components.refer_friend"
                        defaultMessage="Refer a friend"
                      />
                    </Button>
                  </Link>
                </Flex>
              </Media>
            </>
          )}
        </Flex>

        <Flex flex={{ _: '2', largeMobile: '3' }} justifyContent="center">
          <Illustration name="Error404" size="display" />
        </Flex>
      </Flex>
      <MediaWrapper>
        {referFriendTokenValidationErrorType === 'ExpiredTokenError' && (
          <Media lessThan={QUERIES.LARGEMOBILE}>
            <Flex flexDirection={{ _: 'column', tablet: 'row' }} paddingBottom="400" flex="1">
              <Link passHref as={`/${locale}`} href="/[language]" legacyBehavior>
                <ButtonLink compact href={`/${locale}`} dataTest="ExploreButton">
                  <FormattedMessage
                    id="account_v2.component.explore_button"
                    defaultMessage="Explore {companyName}"
                    values={{ companyName }}
                  />
                </ButtonLink>
              </Link>
            </Flex>
          </Media>
        )}
        {referFriendTokenValidationErrorType === 'ReferFriendNotEligibleError' && (
          <Media lessThan={QUERIES.TABLET}>
            <Flex flexDirection={{ _: 'column', tablet: 'row' }} paddingBottom="400" flex="1">
              <Link passHref as={href} href="/[language]/user?tab=refer-friend">
                <Button spaceAfter="medium" fullWidth onClick={() => {}}>
                  <FormattedMessage
                    id="account_v2.components.refer_friend"
                    defaultMessage="Refer a friend"
                  />
                </Button>
              </Link>
              <ButtonLink compact fullWidth href={`/${locale}`}>
                <FormattedMessage
                  id="account_v2.component.explore_button"
                  defaultMessage="Explore {companyName}"
                  values={{ companyName }}
                />
              </ButtonLink>
            </Flex>
          </Media>
        )}
        {referFriendTokenValidationErrorType === 'ReferralTokenOwnerError' && (
          <Media lessThan={QUERIES.LARGEMOBILE}>
            <Flex flexDirection={{ _: 'column', tablet: 'row' }} paddingBottom="400" flex="1">
              <Link passHref as={href} href="/[language]/user?tab=refer-friend">
                <Button fullWidth>
                  <FormattedMessage
                    id="account_v2.components.refer_friend"
                    defaultMessage="Refer a friend"
                  />
                </Button>
              </Link>
            </Flex>
          </Media>
        )}
      </MediaWrapper>
    </Flex>
  );
};

export default ReferFriendTokenErrorScreen;
