import type { $PropertyType } from 'utility-types';
import type { Snapshot, GraphQLTaggedNode, Variables } from 'relay-runtime';
import { createOperationDescriptor, getRequest } from 'relay-runtime';
import type { Environment } from 'react-relay';

type Operation = {
  readonly query: GraphQLTaggedNode;
  readonly variables: Variables;
};

export default function getDataFromRequest(
  { query, variables }: Operation,
  environment: Environment,
): $PropertyType<Snapshot, 'data'> {
  const request = getRequest(query);
  const operation = createOperationDescriptor(request, variables);
  const res = environment.lookup(operation.fragment);
  return res.data;
}
