import { Loading as ActivityIndicator } from '@kiwicom/orbit-components';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Text from './Text';

type ContainerProps = {
  dataTest: string;
};

const Container = styled.div.attrs<ContainerProps>(({ dataTest }) => ({
  'data-test': dataTest,
}))<ContainerProps>`
  flex: 1;
  p {
    margin-top: -20px;
  }
`;
type Props = {
  readonly dataTest?: string;
};

const Loading = (props: Props): JSX.Element => {
  return (
    <Container dataTest={props.dataTest}>
      <ActivityIndicator />
      <Text align="center" type="secondary">
        <FormattedMessage
          id="account_v2.components.loading.info_text"
          defaultMessage="Please wait while we serve up that extra fresh content."
        />
      </Text>
    </Container>
  );
};

export default Loading;
