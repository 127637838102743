import styled from 'styled-components';
import { flexbox, space, layout, color, position, shadow, border } from 'styled-system';
import { defaultTheme as defaultTokens } from '@kiwicom/orbit-components';
import type { ReactNode } from 'react';

import type {
  Spacing,
  Colors,
  Shadows,
  BorderRadius,
  Border,
  OrMediaObject,
  ZIndex,
} from '../StyledSystemTheme';

type Align = OrMediaObject<
  'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit'
>;
type Justify = OrMediaObject<
  'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit'
>;
type Wrap = OrMediaObject<'nowrap' | 'wrap' | 'wrap-reverse' | 'initial' | 'inherit'>;
type Display = OrMediaObject<'block' | 'flex' | 'inline-flex'>; // Expand as needed, https://www.w3schools.com/cssref/pr_class_display.asp

type Overflow = OrMediaObject<'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit'>;
// Expand as needed: https://styled-system.com/api#flexbox, https://styled-system.com/api#space
type Props = Partial<{
  readonly alignContent?: Align;
  readonly alignItems?: OrMediaObject<Align>;
  readonly alignSelf?: Align;
  readonly backgroundColor?: Colors;
  readonly border: Border;
  readonly borderBottom: Border;
  readonly borderLeft: Border;
  readonly borderBottomLeftRadius: BorderRadius;
  readonly borderBottomRightRadius: BorderRadius;
  readonly borderRadius: BorderRadius;
  readonly borderTop: Border;
  readonly borderTopRightRadius: BorderRadius;
  readonly boxShadow: Shadows;
  readonly children: JSX.Element | JSX.Element[] | ReactNode;
  readonly 'data-test'?: string;
  readonly debug?: boolean;
  readonly display: Display;
  readonly flex?: OrMediaObject<string>;
  readonly flexDirection?: OrMediaObject<string>;
  readonly flexWrap?: Wrap;
  readonly justifyContent?: Justify;
  readonly justifyItems?: Justify;
  readonly margin: OrMediaObject<Spacing | string>;
  readonly maxHeight: OrMediaObject<string>;
  readonly maxWidth: OrMediaObject<string>;
  readonly marginBottom?: OrMediaObject<Spacing | string>;
  readonly minHeight: OrMediaObject<string>;
  readonly minWidth: OrMediaObject<string>;
  readonly marginLeft?: Spacing | string;
  readonly marginRight?: Spacing | string;
  readonly marginTop?: Spacing;
  readonly marginY?: Spacing | string;
  readonly overflow: Overflow;
  readonly onClick: () => void;
  readonly padding: OrMediaObject<Spacing | string>;
  readonly paddingBottom?: Spacing | string;
  readonly paddingTop: Spacing;
  readonly paddingLeft: Spacing | string;
  readonly paddingRight: Spacing | string;
  readonly position: OrMediaObject<'relative' | 'absolute' | 'static'>;
  readonly paddingX?: OrMediaObject<Spacing | string>;
  readonly paddingY?: OrMediaObject<Spacing | string>;
  readonly ref?: { current: HTMLElement | null } | ((instance: HTMLElement | null) => void);
  readonly right: OrMediaObject<string>;
  readonly spacing?: OrMediaObject<Spacing | string>;
  readonly top: OrMediaObject<string>;
  readonly left: OrMediaObject<string>;
  readonly width: OrMediaObject<string>;
  readonly height: OrMediaObject<string>;
  readonly zIndex: ZIndex;
  readonly style: Record<string, string>;
}>;

const useSpacing = (
  spacing: OrMediaObject<Spacing> | string,
  flexDirection?: OrMediaObject<string>,
) => {
  const spacingMap = {
    '50': 'space50',
    '100': 'space100',
    '150': 'space150',
    '200': 'space200',
    '300': 'space300',
    '400': 'space400',
    '500': 'space500',
    '600': 'space600',
    '800': 'space800',
    '1000': 'space1000',
    '1200': 'space1200',
    '1600': 'space1600',
  };

  const spacingToken: string = defaultTokens.orbit[spacingMap[spacing as string]];

  if (flexDirection === 'column') {
    return `
      > * {
        margin-bottom: ${spacingToken} ;
      }
      > *:last-child {
        margin-bottom: 0;
      }
    `;
  } else if (flexDirection === 'row') {
    return `
      > * {
        margin-right: ${spacingToken};
      }
      > *:last-child {
        margin-right: 0;
      }
    `;
  }

  // If flexDirection isn't specified then row direction is default
  return `
    > * {
      margin-right: ${spacingToken};
    }
    > *:last-child {
      margin-right: 0;
    }
  `;
};
// Expand as needed
const mapRtlProps = (isRtl, props: Props) => {
  if (!isRtl) {
    return props;
  }

  const { marginRight, marginLeft, paddingRight, paddingLeft, ...rest } = props;

  return {
    ...rest,
    marginLeft: marginRight,
    marginRight: marginLeft,
    paddingLeft: paddingRight,
    paddingRight: paddingLeft,
  };
};

const mapBorderRtlProps = (isRtl, props: Props) => {
  if (!isRtl) {
    return props;
  }

  const { borderTopRightRadius, borderBottomLeftRadius, borderBottomRightRadius, ...rest } = props;
  return {
    ...rest,
    borderTopLeftRadius: borderTopRightRadius,
    borderBottomLeftRadius: borderBottomRightRadius,
    borderBottomRightRadius: borderBottomLeftRadius,
  };
};

// prevent props from leaking onto div, e.g. <div overflow="hidden" class="..."/>
const preventForwardProps = ['display', 'overflow', 'debug', 'width', 'spacing'];

/**
 * Why not orbit stack component? Because sometimes we just want it to be a flex container
 * Orbit stack includes flex item props (basis, grow, shrink).
 */
export const Box = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    return !preventForwardProps.includes(prop) && defaultValidatorFn(prop);
  },
})<Props>`
  ${({ theme, ...rest }) => space({ ...mapRtlProps(theme.rtl, rest), theme })}
  ${flexbox}
  ${color}
  ${layout}
  ${position}
  ${shadow}
  ${({ debug }) => (debug && __DEV__ ? 'outline: 2px solid salmon;' : null)}
  ${({ theme, ...rest }) => border({ ...mapBorderRtlProps(theme.rtl, rest), theme })}
  ${({ spacing, flexDirection }) => spacing && useSpacing(spacing as string, flexDirection)}
`;

export const Flex = styled(Box)<Props>`
  display: flex;
`;
