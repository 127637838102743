import type { TranslationType } from '@kiwicom/account-consts';
import { Heading as OrbitHeading } from '@kiwicom/orbit-components';
import type { Props as OrbitHeadingProps } from '@kiwicom/orbit-components/lib/Heading/types';

export type Props = Readonly<
  OrbitHeadingProps & {
    readonly children: TranslationType;
  }
>;

const Heading = (props: Readonly<Props>): JSX.Element => {
  return <OrbitHeading {...props} />;
};

export default Heading;
