import type { NextPage } from 'next';
import nextCookie from 'next-cookies';
import ReferFriendIntroQuery, { referFriendIntroQuery } from '@referFriend/ReferFriendIntroQuery';

type Props = {
  readonly currency: string;
};

const ReferFriend: NextPage<Props> = ({ currency }) => {
  return <ReferFriendIntroQuery currency={currency} />;
};

ReferFriend.getInitialProps = (ctx) => {
  const { token } = ctx.query;

  if (ctx.res != null) {
    // Related to: https://skypicker.slack.com/archives/CHSH5NC4U/p1613743075166700?thread_ts=1613047371.125600&cid=CHSH5NC4U
    ctx.res.setHeader('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate');
  }

  const cookies = nextCookie(ctx);
  const currency = cookies.preferred_currency ?? 'eur';

  return {
    isSecuredPage: false,
    backgroundColor: '#fff',
    query: referFriendIntroQuery,
    currency,
    variables: {
      token,
    },
    useSimplifiedLoginFlow: true,
  };
};

export default ReferFriend;
