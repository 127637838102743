/**
 * @generated SignedSource<<991574fc66e04582acefc365d6f9cec1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReferFriendIntroQuery$variables = {
  currencyId: string;
  token: string;
};
export type ReferFriendIntroQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ReferFriendIntro_data">;
};
export type ReferFriendIntroQuery = {
  response: ReferFriendIntroQuery$data;
  variables: ReferFriendIntroQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currencyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v2 = {
  "kind": "Variable",
  "name": "currencyId",
  "variableName": "currencyId"
},
v3 = {
  "kind": "Variable",
  "name": "token",
  "variableName": "token"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "formattedValue",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReferFriendIntroQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ReferFriendIntro_data"
      }
    ],
    "type": "RootQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReferFriendIntroQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v3/*: any*/)
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "referFriendTokenValidation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isValid",
                "storageKey": null
              }
            ],
            "type": "ReferFriendTokenValidation",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v2/*: any*/)
        ],
        "concreteType": "ReferFriendPromoValues",
        "kind": "LinkedField",
        "name": "referFriendPromoValues",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Money",
            "kind": "LinkedField",
            "name": "inviteeAmount",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Money",
            "kind": "LinkedField",
            "name": "inviterAmount",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Money",
            "kind": "LinkedField",
            "name": "minimumBookingValue",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "98d64291ab141bda7b1f67ef9c111e74",
    "id": null,
    "metadata": {},
    "name": "ReferFriendIntroQuery",
    "operationKind": "query",
    "text": "query ReferFriendIntroQuery(\n  $token: String!\n  $currencyId: String!\n) {\n  ...ReferFriendIntro_data_2qAJwb\n}\n\nfragment ReferFriendIntro_data_2qAJwb on RootQuery {\n  referFriendTokenValidation(token: $token) {\n    __typename\n    ... on ReferFriendTokenValidation {\n      isValid\n    }\n    ... on ExpiredTokenError {\n      __typename\n    }\n    ... on GeneralError {\n      __typename\n    }\n    ... on ReferFriendNotEligibleError {\n      __typename\n    }\n    ... on ReferralTokenOwnerError {\n      __typename\n    }\n  }\n  referFriendPromoValues(currencyId: $currencyId) {\n    inviteeAmount {\n      formattedValue\n    }\n    inviterAmount {\n      formattedValue\n    }\n    minimumBookingValue {\n      formattedValue\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "312f507eca73d75da67e1f52336c1f7f";

export default node;
