import { captureMutationErrors, graphql, useFragment, useMutation } from '@kiwicom/account-relay';
import {
  Box,
  Button,
  ButtonLink,
  ErrorScreen,
  Flex,
  Heading,
  Text,
} from '@kiwicom/account-components';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth } from '@kiwicom/nitro/lib/services/auth/context';
import Image from 'next/image';
import { useBrand } from '@kiwicom/nitro/lib/services/brand/context';
import { useRouter } from 'next/router';
import { events, useLog, useLogOnMount } from '@kiwicom/account-tracking';
import formatPrice from '@kiwicom/account-homepage/src/PriceAlerts/utils/formatPrice';
import { useFeature } from '@kiwicom/darwin';

import WebviewContext from '../../../services/WebviewContext';
import TimelineComponent from '../Timeline/Timeline';
import AustralianUserErrorScreen from '../error/AustralianUserErrorScreen';
import ReferFriendTokenErrorScreen from '../error/ReferFriendTokenErrorScreen';
import type { ReferFriendIntroMutation } from './__generated__/ReferFriendIntroMutation.graphql';
import { ButtonWrapper, ContentWrapper, ImageWrapper } from './styled';

const ReferFriendIntro = ({ root }) => {
  useLogOnMount(events.referFriend.VISIT_LANDING_PAGE);
  const [signInFulfilled, setSignInFulfilled] = useState(false);
  const [submitInviteTokenError, setSubmitInviteTokenError] = useState(null);
  const { onOpenModal, auth } = useAuth();

  const isAustralianUser = useFeature('DISABLE_REFER_A_FRIEND');
  const { log } = useLog();
  const {
    query: { language, token },
    push,
  } = useRouter();
  const { company_name: companyName } = useBrand();
  const isWebview = useContext(WebviewContext);
  const isSignedIn = auth?.token != null;
  const desktopImageUrl =
    'https://images.skypicker.com/?image=https://images.kiwi.com/account/refer-friend/homepage-desktop.jpg';
  const tabletImageUrl =
    'https://images.skypicker.com/?image=https://images.kiwi.com/account/refer-friend/homepage-tablet.jpg';
  const mobileImageUrl =
    'https://images.skypicker.com/?image=https://images.kiwi.com/account/refer-friend/homepage-mobile.jpg';
  const imageLoader = ({ src }) => src;

  // router query can return string or string[]
  const linkingToken = Array.isArray(token) ? token.join(',')[0] : token;
  const queryLanguage = Array.isArray(language) ? language.join(',')[0] : language;

  const ReferFriendIntroFragment = graphql`
    fragment ReferFriendIntro_data on RootQuery
    @argumentDefinitions(token: { type: "String!" }, currencyId: { type: "String!" }) {
      referFriendTokenValidation(token: $token) {
        ... on ReferFriendTokenValidation {
          isValid
        }
        ... on ExpiredTokenError {
          __typename
        }
        ... on GeneralError {
          __typename
        }
        ... on ReferFriendNotEligibleError {
          __typename
        }
        ... on ReferralTokenOwnerError {
          __typename
        }
      }

      referFriendPromoValues(currencyId: $currencyId) {
        inviteeAmount {
          formattedValue
        }
        inviterAmount {
          formattedValue
        }
        minimumBookingValue {
          formattedValue
        }
      }
    }
  `;
  const { referFriendTokenValidation, referFriendPromoValues } = useFragment(
    ReferFriendIntroFragment,
    root,
  );

  const { inviteeAmount, inviterAmount, minimumBookingValue } = referFriendPromoValues;

  const inviteePromoCodeValue = formatPrice(inviteeAmount?.formattedValue);
  const inviterPromoCodeValue = formatPrice(inviterAmount?.formattedValue);
  const minimumBookingPrice = formatPrice(minimumBookingValue?.formattedValue);

  // Decide what kind of error to show (if any) when validating token on component mount/render
  const referFriendTokenValidationErrorType = referFriendTokenValidation.__typename;
  const isReferFriendTokenInValid =
    referFriendTokenValidationErrorType === 'ExpiredTokenError' ||
    referFriendTokenValidationErrorType === 'ReferralTokenOwnerError' ||
    referFriendTokenValidationErrorType === 'ReferFriendNotEligibleError';
  const isGeneralError =
    referFriendTokenValidationErrorType === 'GeneralError' ||
    submitInviteTokenError === 'GeneralError';

  const [submitReferralToken, isLoading] = useMutation<ReferFriendIntroMutation>(graphql`
    mutation ReferFriendIntroMutation($input: SubmitReferralTokenInput!) {
      submitReferralToken(input: $input) {
        __typename
        ... on SubmitReferralTokenResponse {
          success
        }
        ... on ExpiredTokenError {
          message
        }
        ... on GeneralError {
          message
        }
        ... on ReferFriendNotEligibleError {
          message
        }
        ... on ReferralTokenOwnerError {
          message
        }
      }
    }
  `);

  const submitInviteToken = useCallback(() => {
    submitReferralToken({
      variables: {
        input: {
          linkingToken,
        },
      },
      onCompleted: (response, errors) => {
        if (
          response.submitReferralToken?.__typename === 'SubmitReferralTokenResponse' &&
          response.submitReferralToken?.success
        ) {
          // signInFulfilled means user signed-in on RAF page
          if (signInFulfilled) {
            void push(
              `/[language]/user/refer-friend/[token]/success`,
              `/${queryLanguage}/user/refer-friend/${linkingToken}/success`,
            );
          } else {
            // user landed as signed-in on RAF page so we need to show different success page
            void push(
              `/[language]/user/refer-friend/[token]/success?hadAccount=true`,
              `/${queryLanguage}/user/refer-friend/${linkingToken}/success?hadAccount=true`,
            );
          }
          void log(events.referFriend.SUBMIT_INVITE_TOKEN, {});
        } else {
          // if there's an error validating the referral token we get it through the __typename
          // we should do better error handling on the server and then refactor this
          setSubmitInviteTokenError(response.submitReferralToken?.__typename);
        }
        if (errors != null) {
          // in case of a general server error not related to token validation
          setSubmitInviteTokenError('GeneralError');
          captureMutationErrors(errors);
        }
      },
    });
  }, [submitReferralToken, linkingToken, push, log, signInFulfilled, queryLanguage]);

  useEffect(() => {
    if (signInFulfilled) {
      submitInviteToken();
    }
  }, [signInFulfilled, submitInviteToken]);

  const onOpenSignIn = () => {
    void log(events.referFriend.CLICK_SIGN_IN, {});
    onOpenModal({
      initialScreen: 'intro',
      onSignInFulfill: () => {
        setSignInFulfilled(true);
      },
    });
  };

  const onOpenSignUp = () => {
    void log(events.referFriend.CLICK_CREATE_ACCOUNT, {});
    onOpenModal({
      // @ts-expect-error Workaround for initial screen as email login is screen, but not allowed as initial
      initialScreen: 'emailLogin',
      onSignInFulfill: () => {
        setSignInFulfilled(true);
      },
    });
  };

  if (isAustralianUser) {
    return <AustralianUserErrorScreen />;
  }

  if (isReferFriendTokenInValid || submitInviteTokenError) {
    return (
      <ReferFriendTokenErrorScreen
        referFriendTokenValidationErrorType={
          submitInviteTokenError ? submitInviteTokenError : referFriendTokenValidationErrorType
        }
        inviterAmount={inviterPromoCodeValue}
        inviteeAmount={inviteePromoCodeValue}
      />
    );
  }

  if (isGeneralError) {
    void log(events.referFriend.SHOWED_ERROR_SCREEN, { errorType: 'GeneralError' });
    return <ErrorScreen />;
  }

  if (referFriendTokenValidation.isValid) {
    return (
      <Flex
        height={{ _: 'calc(100vh - 52px)', desktop: 'calc(100vh - 64px)' }}
        flexDirection={{ _: 'column-reverse', desktop: 'row' }}
        alignItems="center"
        maxWidth="1440px"
        margin="0 auto"
        overflow="auto"
        data-test="ReferFriendIntro"
      >
        <ContentWrapper>
          <div>
            <Box marginBottom={{ _: '1000', desktop: '1200' }}>
              <Heading spaceAfter="medium">
                {isSignedIn ? (
                  <FormattedMessage
                    id="account_v2.refer_friend.landing_page.header.title_signed_in"
                    defaultMessage="Get a {promoCodeValue} promo code"
                    values={{ promoCodeValue: inviteePromoCodeValue }}
                  />
                ) : (
                  <FormattedMessage
                    id="account_v2.refer_friend.landing_page.header.title"
                    defaultMessage="Create an account and get a {promoCodeValue} promo code"
                    values={{ promoCodeValue: inviteePromoCodeValue }}
                  />
                )}
              </Heading>
              <Text size="large">
                {isSignedIn ? (
                  <FormattedMessage
                    id="account_v2.refer_friend.landing_page.header.description_signed_in"
                    defaultMessage="You’ve been invited to travel with {companyName}, so both you and your friend can get promo codes for your trips! On top of that, we’ll find you lower prices other booking sites can’t even see, so you’re guaranteed a great deal."
                    values={{ companyName }}
                  />
                ) : (
                  <FormattedMessage
                    id="account_v2.refer_friend.landing_page.header.description"
                    defaultMessage="You’ve been invited to create a {companyName} account, so both you and your friend can get promo codes for your trips! On top of that, we’ll find you lower prices other booking sites can’t even see, so you’re guaranteed a great deal."
                    values={{ companyName }}
                  />
                )}
              </Text>
            </Box>
            <TimelineComponent isSignedIn={isSignedIn} minimumBookingPrice={minimumBookingPrice} />
          </div>
          <ButtonWrapper data-test="ReferFriendButtonWrapper">
            {isSignedIn ? (
              <Button onClick={submitInviteToken} loading={isLoading}>
                <FormattedMessage
                  id="account_v2.refer_friend.landing_page.button.claim"
                  defaultMessage="Claim promo code"
                />
              </Button>
            ) : (
              <>
                <Button onClick={onOpenSignUp}>
                  <FormattedMessage
                    id="account_v2.refer_friend.landing_page.button.create_account"
                    defaultMessage="Create account"
                  />
                </Button>
                <ButtonLink onClick={onOpenSignIn} type="secondary">
                  <FormattedMessage
                    id="account_v2.refer_friend.landing_page.button.sign_in"
                    defaultMessage="Sign in"
                  />
                </ButtonLink>
              </>
            )}
          </ButtonWrapper>
        </ContentWrapper>
        <ImageWrapper {...(isWebview && { isWebview })}>
          <Image
            loader={imageLoader}
            unoptimized
            alt="Refer a friend header image"
            className="imageDesktop"
            src={desktopImageUrl}
            blurDataURL={`${desktopImageUrl}&quality=10`}
            placeholder="blur"
            fill
            priority
            quality={100}
          />
          <Image
            loader={imageLoader}
            unoptimized
            alt="Refer a friend header image"
            className="imageTablet"
            src={tabletImageUrl}
            blurDataURL={`${tabletImageUrl}&quality=10`}
            placeholder="blur"
            fill
            priority
          />
          <Image
            loader={imageLoader}
            unoptimized
            alt="Refer a friend header image"
            className="imageMobile"
            src={mobileImageUrl}
            blurDataURL={`${mobileImageUrl}&quality=10`}
            placeholder="blur"
            fill
            priority
          />
        </ImageWrapper>
      </Flex>
    );
  }
  return null;
};

export default ReferFriendIntro;
