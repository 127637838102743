import type { PayloadError } from 'relay-runtime';
import { captureException } from '@kiwicom/account-sentry';

const captureMutationErrors = (errors: PayloadError[]) => {
  errors?.forEach((error) => {
    captureException(new Error(error.message));
  });
};

export default captureMutationErrors;
