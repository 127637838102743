/**
 * @generated SignedSource<<2c15aa0e6fb26655f984afffceb3464d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReferFriendIntro_data$data = {
  readonly referFriendPromoValues: {
    readonly inviteeAmount: {
      readonly formattedValue: string | null | undefined;
    } | null | undefined;
    readonly inviterAmount: {
      readonly formattedValue: string | null | undefined;
    } | null | undefined;
    readonly minimumBookingValue: {
      readonly formattedValue: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly referFriendTokenValidation: {
    readonly __typename: "ExpiredTokenError";
    readonly isValid?: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ReferFriendIntro_data";
};
export type ReferFriendIntro_data$key = {
  readonly " $data"?: ReferFriendIntro_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReferFriendIntro_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "formattedValue",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currencyId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "token"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReferFriendIntro_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "token",
          "variableName": "token"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "referFriendTokenValidation",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isValid",
              "storageKey": null
            }
          ],
          "type": "ReferFriendTokenValidation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "ExpiredTokenError",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "GeneralError",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "ReferFriendNotEligibleError",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "ReferralTokenOwnerError",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "currencyId",
          "variableName": "currencyId"
        }
      ],
      "concreteType": "ReferFriendPromoValues",
      "kind": "LinkedField",
      "name": "referFriendPromoValues",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Money",
          "kind": "LinkedField",
          "name": "inviteeAmount",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Money",
          "kind": "LinkedField",
          "name": "inviterAmount",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Money",
          "kind": "LinkedField",
          "name": "minimumBookingValue",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RootQuery",
  "abstractKey": null
};
})();

(node as any).hash = "7c01bdb70f2b5e89ba9f4dc731fef1e4";

export default node;
