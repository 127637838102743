import type { TranslationType } from '@kiwicom/account-consts';
import { Text as OrbitText } from '@kiwicom/orbit-components';
import type { Props as OrbitTextProps } from '@kiwicom/orbit-components/lib/Text/types';

type Props = Readonly<
  OrbitTextProps & {
    readonly children: TranslationType | TranslationType[];
  }
>;

const Text = (props: Readonly<Props>): JSX.Element => {
  return <OrbitText {...props} />;
};

export default Text;
