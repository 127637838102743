/**
 * @generated SignedSource<<4728713a28aa794a6fa7189c9c4041a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubmitReferralTokenInput = {
  linkingToken: string;
};
export type ReferFriendIntroMutation$variables = {
  input: SubmitReferralTokenInput;
};
export type ReferFriendIntroMutation$data = {
  readonly submitReferralToken: {
    readonly __typename: "ExpiredTokenError";
    readonly message: string | null | undefined;
  } | {
    readonly __typename: "GeneralError";
    readonly message: string | null | undefined;
  } | {
    readonly __typename: "ReferFriendNotEligibleError";
    readonly message: string | null | undefined;
  } | {
    readonly __typename: "ReferralTokenOwnerError";
    readonly message: string | null | undefined;
  } | {
    readonly __typename: "SubmitReferralTokenResponse";
    readonly success: boolean | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type ReferFriendIntroMutation = {
  response: ReferFriendIntroMutation$data;
  variables: ReferFriendIntroMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "submitReferralToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          }
        ],
        "type": "SubmitReferralTokenResponse",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "ExpiredTokenError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "GeneralError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "ReferFriendNotEligibleError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "ReferralTokenOwnerError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReferFriendIntroMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReferFriendIntroMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "94eba8d279cdec74ddb32c80297ee080",
    "id": null,
    "metadata": {},
    "name": "ReferFriendIntroMutation",
    "operationKind": "mutation",
    "text": "mutation ReferFriendIntroMutation(\n  $input: SubmitReferralTokenInput!\n) {\n  submitReferralToken(input: $input) {\n    __typename\n    ... on SubmitReferralTokenResponse {\n      success\n    }\n    ... on ExpiredTokenError {\n      message\n    }\n    ... on GeneralError {\n      message\n    }\n    ... on ReferFriendNotEligibleError {\n      message\n    }\n    ... on ReferralTokenOwnerError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8da5a7f49da001a640baeda23f809f1c";

export default node;
