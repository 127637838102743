import { forwardRef } from 'react';
import { ButtonLink as OrbitButtonLink } from '@kiwicom/orbit-components';
import type { Props as ButtonLinkProps } from '@kiwicom/orbit-components/lib/ButtonLink/types';

type Props = Readonly<
  ButtonLinkProps & {
    ariaLabel?: string;
    title?: string;
  }
>;

const ButtonLink = ({ ariaLabel, ...rest }: Props, ref) => {
  const props = { ...rest };

  return <OrbitButtonLink title={ariaLabel} {...props} ref={ref} />;
};

export default forwardRef(ButtonLink);
