import { Button, ButtonLink, Flex, Heading, right, Text } from '@kiwicom/account-components';
import { Illustration } from '@kiwicom/orbit-components';
import styled from 'styled-components';
import { Media } from '@kiwicom/account-global-contexts';
import { events, useLogOnMount } from '@kiwicom/account-tracking';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from '@kiwicom/nitro/lib/services/auth/context';
import { useRouter } from 'next/router';
import { useBrand } from '@kiwicom/nitro/lib/services/brand/context';

const MediaWrapper = styled.div`
  width: 100%;
`;

const FlexWithSpacing = styled(Flex)`
  > *:first-child {
    margin-${right}: ${({ theme }) => theme.orbit.space600};
  }
`;

const AustralianUserErrorScreen = () => {
  const intl = useIntl();
  const { locale } = intl;
  const { onOpenModal } = useAuth();
  const { push } = useRouter();
  const { name: brandName } = useBrand();

  useLogOnMount(events.referFriend.SHOWED_ERROR_SCREEN, {
    errorType: 'ReferFriendNotEligibleError',
  });

  const onOpenSignUp = () => {
    onOpenModal({
      // @ts-expect-error Workaround for initial screen as email login is screen, but not allowed as initial
      initialScreen: 'emailLogin',
      onSignInFulfill: () => {
        push(`/${locale}/user?action=triggerOnboarding`);
      },
    });
  };
  return (
    <Flex
      height={{ _: 'calc(100vh - 52px)', largeMobile: 'calc(100vh - 64px)' }}
      flexDirection="column"
      justifyContent={{ _: 'space-between', largeMobile: 'center' }}
      alignItems="center"
      maxWidth="1024px"
      margin="0 auto"
      padding="0 32px"
    >
      <Flex
        alignItems="center"
        flexDirection={{ _: 'column-reverse', largeMobile: 'row' }}
        flex="3"
      >
        <Flex flexDirection="column" flex="2">
          <Heading spaceAfter="medium" type="title2">
            <FormattedMessage
              id="account_v2.refer_friend.australian_user.title"
              defaultMessage="Sorry, friend referrals are not available in Australia yet"
              description="Figma: https://www.figma.com/file/3RibGwfx31SJ0UdWk3Xmku/%5BAccount%5D-Refer-a-friend?type=design&node-id=6310-52875&mode=dev"
            />
          </Heading>
          <Text size="large" spaceAfter="large">
            <FormattedMessage
              id="account_v2.refer_friend.australian_user.description"
              defaultMessage="But thank you for your interest in {COMPANY_NAME}! You can still create an account and book amazingly cheap flights. We’d be thrilled to have you on board!"
              description="Figma: https://www.figma.com/file/3RibGwfx31SJ0UdWk3Xmku/%5BAccount%5D-Refer-a-friend?type=design&node-id=6310-52875&mode=dev"
              values={{
                COMPANY_NAME: brandName,
              }}
            />
          </Text>
          <Media greaterThanOrEqual="tablet">
            <FlexWithSpacing
              flexDirection={{ _: 'column', tablet: 'row' }}
              alignItems="center"
              justifyContent="flex-start"
              spacing="600"
            >
              <Button onClick={onOpenSignUp}>
                <FormattedMessage
                  id="account_v2.refer_friend.landing_page.button.create_account"
                  defaultMessage="Create account"
                />
              </Button>
              <ButtonLink compact href={`/${locale}`}>
                <FormattedMessage
                  id="account_v2.component.go_to_search_button"
                  defaultMessage="Go to search"
                  description="Figma: https://www.figma.com/file/3RibGwfx31SJ0UdWk3Xmku/%5BAccount%5D-Refer-a-friend?type=design&node-id=6315-52892&mode=dev"
                />
              </ButtonLink>
            </FlexWithSpacing>
          </Media>
        </Flex>
        <Flex flex={{ _: '2', largeMobile: '3' }} justifyContent="center">
          <Illustration name="Error404" size="display" />
        </Flex>
      </Flex>
      <MediaWrapper>
        <Media lessThan="tablet">
          <Flex flexDirection={{ _: 'column', tablet: 'row' }} paddingBottom="400" flex="1">
            <Button fullWidth onClick={onOpenSignUp}>
              <FormattedMessage
                id="account_v2.refer_friend.landing_page.button.create_account"
                defaultMessage="Create account"
              />
            </Button>
            <ButtonLink compact href={`/${locale}`} dataTest="ExploreButton">
              <FormattedMessage
                id="account_v2.component.go_to_search_button"
                defaultMessage="Go to search"
                description="Figma: https://www.figma.com/file/3RibGwfx31SJ0UdWk3Xmku/%5BAccount%5D-Refer-a-friend?type=design&node-id=6315-52892&mode=dev"
              />
            </ButtonLink>
          </Flex>
        </Media>
      </MediaWrapper>
    </Flex>
  );
};

export default AustralianUserErrorScreen;
