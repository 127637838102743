import type { ReactElement } from 'react';
import type { GraphQLTaggedNode, Environment } from 'react-relay';
import { QueryRenderer as RelayQueryRenderer, LocalQueryRenderer } from 'react-relay';
import { isBrowser } from '@adeira/js';
import { useSSRData } from '@kiwicom/account-global-contexts';
import type { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';

import Loading from './components/Loading';
import onSystemError from './components/onSystemError';
import getDataFromRequest from './getDataFromRequest';

type Props = {
  readonly environment: Environment;
  readonly query: GraphQLTaggedNode;
  readonly onResponse: (arg0: unknown) => ReactElement;
  readonly variables?: Record<string, unknown>;
  readonly isInitialQuery?: boolean;
  readonly fetchPolicy?: 'store-and-network' | 'network-only';
  readonly ssrData?: RecordMap;
  readonly onLoading?: JSX.Element;
  readonly withoutErrorScreen?: boolean;
};

const QueryRenderer = (props: Props): ReactElement => {
  const { useLocalQueryRenderer, fetchError } = useSSRData();
  // hreflang is the same as iso
  const { environment } = props;
  const { variables = {}, query, withoutErrorScreen = false, fetchPolicy, isInitialQuery } = props;
  const data = getDataFromRequest(
    {
      query,
      variables,
    },
    environment,
  );

  if (!isBrowser()) {
    // When we get here on the server, we have already fetched data
    // Using the QueryRenderer will dispatch a new request to BE, so just return
    // The data from the store instead.
    if (data != null) {
      // Data will always include the query with fragments, so we need also to check if we have a fetchError
      if (!fetchError) {
        return props.onResponse(data);
      }
      if (Object.prototype.hasOwnProperty.call(props, 'onLoading')) {
        return props.onLoading ?? null;
      }

      return <Loading />;
    }
  } else if (isBrowser() && isInitialQuery && useLocalQueryRenderer) {
    // QueryRenderer repeats the same request the server fetched moments ago
    // This should just use the stored data instead, and we save one request to BE
    // Also, note that LocalQueryRenderer will not work on the server, since it relies on client hooks
    return (
      <LocalQueryRenderer
        query={props.query}
        environment={environment}
        variables={props.variables}
        render={({ error, props: renderProps }) => {
          if (error && !withoutErrorScreen) {
            return onSystemError();
          }

          if (renderProps) {
            return props.onResponse(renderProps);
          }

          return <Loading />;
        }}
      />
    );
  }

  // Any nested query renderer will end up here
  return (
    <RelayQueryRenderer
      query={query}
      environment={environment}
      variables={variables}
      render={({ error, props: renderProps }) => {
        if (error && !withoutErrorScreen) {
          return onSystemError();
        }

        if (renderProps) {
          return props.onResponse(renderProps);
        }

        if (Object.prototype.hasOwnProperty.call(props, 'onLoading')) {
          return props.onLoading ?? null;
        }

        return <Loading />;
      }}
      fetchPolicy={fetchPolicy ?? 'store-and-network'}
    />
  );
};

export default QueryRenderer;
