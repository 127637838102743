import { CheckCircle, CircleFilled, Circle } from '@kiwicom/orbit-components/icons';
import { FormattedMessage } from 'react-intl';
import { Text } from '@kiwicom/account-components';
import { useBrand } from '@kiwicom/nitro/lib/services/brand/context';

import { Container, Step, StepContent } from './styled';

type Props = {
  isSignedIn: boolean;
  minimumBookingPrice: string;
};

const TimelineComponent = ({ isSignedIn, minimumBookingPrice }: Props) => {
  const { company_name: companyName } = useBrand();

  return (
    <Container>
      <Step isPrimary>
        <StepContent>
          <CheckCircle size="small" customColor="#00A58E" />
          <Text size="large">
            <FormattedMessage
              id="account_v2.refer_friend.landing_page.timeline.received_link"
              defaultMessage="You received your friend’s invitation link."
            />
          </Text>
        </StepContent>
      </Step>
      <Step isSecondary>
        <StepContent>
          <CircleFilled size="small" customColor="#00A58E" />
          <Text size="large">
            {isSignedIn ? (
              <FormattedMessage
                id="account_v2.refer_friend.landing_page.timeline.login"
                defaultMessage="Log in to your {companyName} account."
                description="Designer: Anja Bošnjak Mihić; Figma: https://www.figma.com/file/3RibGwfx31SJ0UdWk3Xmku/%5BAccount%5D-Refer-a-friend?node-id=2175%3A43495"
                values={{ companyName }}
              />
            ) : (
              <FormattedMessage
                id="account_v2.refer_friend.landing_page.timeline.create_account"
                defaultMessage="Create your Kiwi.com account using the button below."
              />
            )}
          </Text>
        </StepContent>
      </Step>
      <Step isSecondary>
        <StepContent>
          <Circle size="small" customColor="#E8EDF1" />
          <Text size="large">
            <FormattedMessage
              id="account_v2.refer_friend.landing_page.timeline.receive_promo_code"
              defaultMessage="Expect an email with your promo code."
            />
          </Text>
        </StepContent>
      </Step>
      <Step isSecondary>
        <StepContent>
          <Circle size="small" customColor="#E8EDF1" />
          <Text size="large">
            <FormattedMessage
              id="account_v2.refer_friend.landing_page.timeline.use_promo_codes"
              defaultMessage="Use your promo code for a trip over {minimumTripPrice} and your friend will get their code too."
              values={{ minimumTripPrice: minimumBookingPrice }}
            />
          </Text>
        </StepContent>
      </Step>
      <Step>
        <StepContent>
          <Circle size="small" customColor="#E8EDF1" />
          <Text size="large">
            <FormattedMessage
              id="account_v2.refer_friend.landing_page.timeline.refer_friends"
              defaultMessage="Refer your friends to Kiwi.com to get more promo codes!"
            />
          </Text>
        </StepContent>
      </Step>
    </Container>
  );
};

export default TimelineComponent;
