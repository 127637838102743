import styled from 'styled-components';
import { Flex, right } from '@kiwicom/account-components';

const MediaWrapper = styled.div`
  width: 100%;
`;

const FlexWithRightMargin = styled(Flex)`
  > *:first-child {
    margin-${right}: ${({ theme }) => theme.orbit.space600};
  }
`;

export { MediaWrapper, FlexWithRightMargin };
