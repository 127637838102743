import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import { Button as OrbitButton } from '@kiwicom/orbit-components';
import type { Props as OrbitButtonProps } from '@kiwicom/orbit-components/lib/Button/types';
import { mediaQueries as media } from '@kiwicom/account-global-contexts';
import type { TranslationType } from '@kiwicom/account-consts';
import styled, { css } from 'styled-components';

type MediaValues =
  | 'smallMobile'
  | 'mediumMobile'
  | 'largeMobile'
  | 'tablet'
  | 'desktop'
  | 'largeDesktop';

type WrapperProps = {
  fullWidthUntil: MediaValues | 'all' | 'none';
  iconLeft: ReactNode;
  iconRight: ReactNode;
};

const Wrapper = styled.div<WrapperProps>`
  width: ${({ fullWidthUntil }) => (fullWidthUntil === 'none' ? 'auto' : '100%')};

  ${({ fullWidthUntil }) => {
    const mediaQuery = media[fullWidthUntil];

    if (mediaQuery != null) {
      return mediaQuery(css`
        width: auto;
      `);
    }

    return '';
  }}
  ${({ iconLeft, fullWidthUntil }) =>
    iconLeft != null && fullWidthUntil !== 'none'
      ? `
  button, a {
    & > div:first-child {
      justify-content: center;
      & > div:last-child {
        width: auto;
      }
    }
  }
  `
      : ''}
      ${({ iconRight, fullWidthUntil }) =>
    iconRight != null && fullWidthUntil !== 'none'
      ? `
  button, a {
    & > div:first-child {
      justify-content: center;
      > *:first-child {
        width: auto;
    }
    }
  }
  `
      : ''}
`;
type Props = Readonly<
  OrbitButtonProps & {
    readonly children?: TranslationType;
    readonly title?: string;
    readonly fullWidthUntil?: MediaValues | 'all';
  }
>;

const Button = ({ title, fullWidthUntil, fullWidth, ...rest }: Props, ref) => {
  const props = { ...rest };

  const isFullWidth = Boolean(fullWidth || fullWidthUntil != null) || undefined;

  return (
    <Wrapper
      fullWidthUntil={fullWidth ? 'all' : fullWidthUntil ?? 'none'}
      iconLeft={rest.iconLeft}
      iconRight={rest.iconRight}
    >
      <OrbitButton title={title} {...props} fullWidth={isFullWidth} ref={ref} />
    </Wrapper>
  );
};

export default forwardRef<HTMLButtonElement, Props>(Button);
