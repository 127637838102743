import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useLog } from '@kiwicom/nitro/lib/services/log/context';
import type { Props as EventProps } from '@kiwicom/nitro/lib/records/Event';

import '@kiwicom/nitro/lib/records/Event';
import type { Event } from '../..';
import '../..';

const emptyObject = {};

const useLogOnMount = (event: Event, eventProps?: EventProps): void => {
  const { log } = useLog();
  useEffect(() => {
    void log(event, eventProps ?? emptyObject);
  }, [log, event, eventProps]);
};

export default useLogOnMount;
