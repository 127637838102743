import styled from 'styled-components';
import { Illustration, Layout, Stack } from '@kiwicom/orbit-components';
import type { TranslationType } from '@kiwicom/account-consts';
import type { Props as IllustrationProps } from '@kiwicom/orbit-components/lib/Illustration/types';
import { FormattedMessage } from 'react-intl';

import LayoutColumn from '../LayoutColumn';
import Text from '../Text';
import Heading from '../Heading';

type WrapperProps = {
  withoutMargin: boolean;
  dataTest: string;
};

const Wrapper = styled.div.attrs<WrapperProps>(({ dataTest }) => ({
  'data-test': dataTest,
}))<WrapperProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ withoutMargin }) => !withoutMargin && '100px'};
`;
type Props = {
  readonly illustrationName?: IllustrationProps['name'];
  readonly title?: TranslationType;
  readonly text?: TranslationType;
  readonly dataTest?: string;
  readonly withoutMargin?: boolean;
};

const ErrorScreen = ({
  illustrationName = 'Error404',
  title,
  text,
  dataTest = 'QueryRendererErrorScreen',
  withoutMargin = false,
}: Props): JSX.Element => {
  return (
    <Layout type="MMB">
      <LayoutColumn>
        <Wrapper withoutMargin={withoutMargin} dataTest={dataTest}>
          <Stack
            tablet={{
              direction: 'row',
            }}
            direction="column"
            align="center"
            flex
            justify="between"
            spacing="600"
          >
            <Stack>
              <Stack
                justify="center"
                tablet={{
                  justify: 'start',
                }}
              >
                <Illustration name={illustrationName} />
              </Stack>
            </Stack>
            <Stack>
              <Stack
                direction="column"
                align="center"
                tablet={{
                  align: 'start',
                }}
              >
                <Heading type="title2" as="h2">
                  {title ?? (
                    <FormattedMessage
                      id="account_v2.components.error_screen.title"
                      defaultMessage="Something went wrong"
                    />
                  )}
                </Heading>
                <Text type="secondary">
                  {text ?? (
                    <FormattedMessage
                      id="account_v2.components.error_screen.text"
                      defaultMessage="Sorry about that. It looks like we're having some technical issues. Refresh the page or try again later."
                    />
                  )}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Wrapper>
      </LayoutColumn>
    </Layout>
  );
};

export default ErrorScreen;
